<template>
  <div>
    <BaseModal
      dataClass="definition-item-modify-modal"
      size="lg"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <template v-slot:main>
        <div data-class="work-list" class="mx-2 my-2">
          <BaseTabGroup
            ref="tab-group"
            :inputModel="sectionDivisionEdit"
            :inputComponents="inputComponents"
          ></BaseTabGroup>
          <br />
        </div>
      </template>
      <template v-slot:footer="{ cancel }">
        <!-- 設定ボタン -->
        <BaseButton
          variant="primary"
          icon="check-circle"
          :columnInfo="resultControlInfo.setting"
          @click="setting(obj)"
        />
        <!-- 閉じるボタン -->
        <BaseButton
          variant="primary"
          icon="times-circle"
          :columnInfo="resultControlInfo.close"
          @click="cancel"
        />
      </template>
    </BaseModal>
    <!--Sectionモーダル -->
    <DepartmentModal
      id="department-modal"
      type="entry"
      :selectedSearchCondition="departmentModalSearchCondition"
      @after-close-set="afterCloseSetDepartment"
      @after-close-unset="afterCloseUnsetDepartment"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
    <!-- 場所マスタモーダル -->
    <PlaceModal
      id="place-modal-edit"
      type="entry"
      displayModals="two"
      @click="select('place-modal-edit')"
      @after-close-set="afterCloseSetPlaceEdit"
      @after-close-unset="afterCloseUnsetPlaceEdit"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
    <!-- Vehicleモーダル -->
    <VehicleModal
      id="vehicle-modal-three"
      type="entry"
      selectMode="single"
      displayModals="three"
      @after-close-set="afterCloseSetVehicle"
      @after-close-unset="afterCloseUnsetVehicle"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
  </div>
</template>

<script>
import SectionDivisionEditInfo from './SectionDivisionEditInfo.js'
import { getListOptions, openSubModal } from '@/common/Common.js'
import {
  SCREEN_ID,
  VISIBILITY,
  DEPARTMENT_KIND,
  DETAIL_SCREEN_KBN,
} from '@/common/const.js'
import {
  init,
  setting,
  setOnlyDepartureDisable,
  setOnlyArrivalDisable,
  setBaseDeparturePlaceInfo,
  setBaseArrivalPlaceInfo,
  setDefaultArrivalPlaceInfo,
} from '@/master/sectionDivision/SectionDivisionEdit.js'

const screenId = SCREEN_ID.SECTION_DIVISION_EDIT

export default {
  components: {
    DepartmentModal: () => import('@/master/department/DepartmentModal.vue'),
    PlaceModal: () => import('@/master/place/PlaceModal.vue'),
    VehicleModal: () => import('@/master/vehicle/VehicleModal.vue'),
  },
  props: {
    getDatas: {
      type: Object,
      default: () => {},
    },
  },

  watch: {
    getDatas() {
      init(this)
    },
  },

  data() {
    return {
      sectionDivisionEdit: new SectionDivisionEditInfo(),
      modalKbn: 0,
      obj: this,
      screenKbn: -1,
      departmentModalSearchCondition: {},
    }
  },

  computed: {
    inputComponents() {
      return [
        {
          // タブ
          label: this.detailInfo.tab1.label,
          inputComponents: [
            {
              // 分割ブロック
              type: 'select',
              id: 'targetBlockCode',
              columnInfo: this.detailInfo.target_block_code,
              options: getListOptions(this.MASTER_CODE.BLOCK, true),
              //rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onChangeCallback: () => {},
            },
            {
              // 出発地
              type: 'referenceCodeName',
              codeId: 'departurePlaceId',
              nameId: 'departurePlaceName',
              codeColumnInfo: this.detailInfo.departure_place_id,
              nameColumnInfo: this.detailInfo.departure_place_name,
              btnColumnInfo: this.detailInfo.sub_modal_departure_place,
              codeRules: [this.INPUT_TYPE_CHECK.REQUIRED],
              nameRules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onClickCallback: () => {
                this.modalKbn = 2
                this.openSubModal(this, 'place-modal-edit')
              },
            },
            {
              // 到着地
              type: 'referenceCodeName',
              codeId: 'arrivalPlaceId',
              nameId: 'arrivalPlaceName',
              codeColumnInfo: this.detailInfo.arrival_place_id,
              nameColumnInfo: this.detailInfo.arrival_place_name,
              btnColumnInfo: this.detailInfo.sub_modal_arrival_place,
              codeRules: [this.INPUT_TYPE_CHECK.REQUIRED],
              nameRules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onClickCallback: () => {
                this.modalKbn = 3
                this.openSubModal(this, 'place-modal-edit')
              },
            },
            {
              // モーダル区分
              type: 'select',
              id: 'modalType',
              columnInfo: this.detailInfo.modal_type,
              options: getListOptions(this.MASTER_CODE.MODAL_TYPE),
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onChangeCallback: () => {},
            },
            {
              // 協力企業名
              type: 'referenceCodeName',
              codeId: 'carrierId',
              nameId: 'carrierName',
              codeColumnInfo: this.detailInfo.carrier_id,
              nameColumnInfo: this.detailInfo.carrier_name,
              btnColumnInfo: this.detailInfo.sub_modal_carrier,
              //codeRules: [this.INPUT_TYPE_CHECK.REQUIRED],
              //nameRules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onClickCallback: () => {
                this.departmentModalSearchCondition = {}
                this.departmentModalSearchCondition.departmentKind =
                  DEPARTMENT_KIND.CARRIER
                this.openSubModal(this, 'department-modal')
              },
            },
            {
              // 車両番号
              type: 'referenceCodeName',
              codeId: 'vehicleId',
              nameId: 'numberplate',
              codeColumnInfo: this.detailInfo.vehicle_id,
              nameColumnInfo: this.detailInfo.numberplate,
              btnColumnInfo: this.detailInfo.sub_modal_vehicle,
              //codeRules: [this.INPUT_TYPE_CHECK.REQUIRED],
              //nameRules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onClickCallback: () => {
                this.openSubModal(this, 'vehicle-modal-three')
              },
            },
            {
              // 自動配車対象外フラグ
              type: 'select',
              id: 'autoPlanningExcludeFlag',
              columnInfo: this.detailInfo.auto_planning_exclude_flag,
              options: getListOptions(
                this.MASTER_CODE.AUTO_PLANNING_EXCLUDE_FLAG
              ),
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onChangeCallback: () => {},
            },
            {
              // 発時間
              type: 'timeFromTo',
              fromId: 'departureTimeFrom',
              toId: 'departureTimeTo',
              fromColumnInfo: this.detailInfo.departure_time_from,
              toColumnInfo: this.detailInfo.departure_time_to,
              fromRules: [this.INPUT_TYPE_CHECK.DATETIMERANGE],
            },
            {
              // 着時間
              type: 'timeFromTo',
              fromId: 'arrivalTimeFrom',
              toId: 'arrivalTimeTo',
              fromColumnInfo: this.detailInfo.arrival_time_from,
              toColumnInfo: this.detailInfo.arrival_time_to,
              fromRules: [this.INPUT_TYPE_CHECK.DATETIMERANGE],
            },
            {
              // 出発加算日数
              type: 'text',
              id: 'departureAddDays',
              columnInfo: this.detailInfo.departure_add_days,
              rules: [this.INPUT_TYPE_CHECK.ONLY_NUMERIC],
            },
            {
              // 到着加算日数
              type: 'text',
              id: 'arrivalAddDays',
              columnInfo: this.detailInfo.arrival_add_days,
              rules: [this.INPUT_TYPE_CHECK.ONLY_NUMERIC],
            },
          ],
        },
      ]
    },

    detailInfo() {
      let info = this.$store.getters['init/getScreens'](screenId)('detail_info')

      info.departure_place_id.visibility = VISIBILITY.DISABLE
      info.departure_place_name.visibility = VISIBILITY.DISABLE
      info.arrival_place_id.visibility = VISIBILITY.DISABLE
      info.arrival_place_name.visibility = VISIBILITY.DISABLE

      info.carrier_id.visibility = VISIBILITY.DISABLE
      info.carrier_name.visibility = VISIBILITY.DISABLE
      info.vehicle_id.visibility = VISIBILITY.DISABLE
      info.numberplate.visibility = VISIBILITY.DISABLE

      info.sub_modal_departure_place.visibility = VISIBILITY.VISIBLE
      info.sub_modal_arrival_place.visibility = VISIBILITY.VISIBLE

      //Row==0
      if (this.getDatas.rowKbn == 0) {
        this.setOnlyDepartureDisable(info)
        this.setBaseDeparturePlaceInfo(this)
        this.setOnlyArrivalDisable(info)
        this.setBaseArrivalPlaceInfo(this)
      }
      this.setDefaultArrivalPlaceInfo(this)
      this.setOnlyArrivalDisable(info)
      if (
        this.getDatas.selectedItem != null &&
        this.getDatas.selectedItem.length > 0
      ) {
        // 出発地 (firstRow、編集の際、出発地固定)
        if (
          this.getDatas.rowKbn == 1 &&
          this.getDatas.detailScreenKbn == DETAIL_SCREEN_KBN.DETAIL &&
          this.getDatas.selectedItem[0].departure_place_id ==
            this.getDatas.baseDeparturePlaceId
        ) {
          this.setOnlyDepartureDisable(info)
          this.setBaseDeparturePlaceInfo(this)
        }
        // 到着地
        if (
          this.getDatas.selectedItem[0].arrival_place_id ==
          this.getDatas.baseArrivalPlaceId
        ) {
          this.setBaseArrivalPlaceInfo(this)
        }
      }
      return info
    },
    resultControlInfo() {
      return this.$store.getters['init/getScreens'](screenId)(
        'result_ctrl_info'
      )
    },
  },

  methods: {
    getListOptions,
    init,
    openSubModal,
    setOnlyDepartureDisable,
    setOnlyArrivalDisable,
    setBaseDeparturePlaceInfo,
    setBaseArrivalPlaceInfo,
    setDefaultArrivalPlaceInfo,
    setting,

    afterCloseSetPlaceEdit(selectItem) {
      if (this.modalKbn == 1) {
        this.obj.sectionDivisionEdit.transitPlaceId = selectItem.place_id
        this.obj.sectionDivisionEdit.transitPlaceName = selectItem.place_name
      } else if (this.modalKbn == 2) {
        this.obj.sectionDivisionEdit.departurePlaceId = selectItem.place_id
        this.obj.sectionDivisionEdit.departurePlaceName = selectItem.place_name
      } else if (this.modalKbn == 3) {
        this.obj.sectionDivisionEdit.arrivalPlaceId = selectItem.place_id
        this.obj.sectionDivisionEdit.arrivalPlaceName = selectItem.place_name
      }
    },
    afterCloseUnsetPlaceEdit() {
      if (this.modalKbn == 1) {
        this.obj.sectionDivisionEdit.transitPlaceId = null
        this.obj.sectionDivisionEdit.transitPlaceName = null
      } else if (this.modalKbn == 2) {
        this.obj.sectionDivisionEdit.departurePlaceId = null
        this.obj.sectionDivisionEdit.departurePlaceName = null
      } else if (this.modalKbn == 3) {
        this.obj.sectionDivisionEdit.arrivalPlaceId = null
        this.obj.sectionDivisionEdit.arrivalPlaceName = null
      }
    },

    afterCloseSetDepartment(id, code, name) {
      this.obj.sectionDivisionEdit.carrierId = id
      this.obj.sectionDivisionEdit.carrierName = name
    },

    afterCloseUnsetDepartment() {
      this.obj.sectionDivisionEdit.carrierId = null
      this.obj.sectionDivisionEdit.carrierName = null
    },

    afterCloseSetVehicle(selectItem) {
      this.obj.sectionDivisionEdit.vehicleId = selectItem.vehicle_id
      var carNum = selectItem.numberplate
      this.obj.sectionDivisionEdit.numberplate = carNum
    },
    afterCloseUnsetVehicle() {
      this.obj.sectionDivisionEdit.vehicleId = null
      this.obj.sectionDivisionEdit.numberplate = null
    },

    setErrorMsg(msg) {
      this.$emit('set-error-msg', msg)
    },

    setWarnMsg(msg) {
      this.$emit('set-warn-msg', msg)
    },
  },
}
</script>

<style scoped></style>
