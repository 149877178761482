import { MSG_TYPE, DETAIL_SCREEN_KBN, VISIBILITY } from '@/common/const.js'
import { openMsgModal, formatHHmmss } from '@/common/Common.js'
import SectionDivisionEdit from './SectionDivisionEditInfo'
import _ from 'lodash'

export function init(obj) {
  if (obj.getDatas.detailScreenKbn == DETAIL_SCREEN_KBN.NEW) {
    //追加
    obj.sectionDivisionEdit = new SectionDivisionEdit()
    let loginUserBlockCode = obj.$store.getters['authentication/getBlockCode']
    //ブロック
    obj.sectionDivisionEdit.targetBlockCode = loginUserBlockCode
  } else {
    //編集
    //ブロック
    obj.sectionDivisionEdit.targetBlockCode =
      obj.getDatas.selectedItem[0].target_block_code
    // 出発地ＩＤ
    obj.sectionDivisionEdit.departurePlaceId =
      obj.getDatas.selectedItem[0].departure_place_id
    obj.sectionDivisionEdit.departurePlaceName =
      obj.getDatas.selectedItem[0].departure_place_name
    // 到着地ＩＤ
    obj.sectionDivisionEdit.arrivalPlaceId =
      obj.getDatas.selectedItem[0].arrival_place_id
    obj.sectionDivisionEdit.arrivalPlaceName =
      obj.getDatas.selectedItem[0].arrival_place_name
    // モーダル区分
    obj.sectionDivisionEdit.modalType = obj.getDatas.selectedItem[0].modal_type
    // 協力企業ＩＤ
    obj.sectionDivisionEdit.carrierId = obj.getDatas.selectedItem[0].carrier_id
    obj.sectionDivisionEdit.carrierName =
      obj.getDatas.selectedItem[0].carrier_name
    // 車両番号
    obj.sectionDivisionEdit.vehicleId = obj.getDatas.selectedItem[0].vehicle_id
    obj.sectionDivisionEdit.numberplate =
      obj.getDatas.selectedItem[0].numberplate

    //自動配車対象外フラグ
    obj.sectionDivisionEdit.autoPlanningExclueFlag =
      obj.getDatas.selectedItem[0].auto_planning_exclue_flag
    // 発時間
    obj.sectionDivisionEdit.departureTimeFrom = formatHHmmss(
      obj.getDatas.selectedItem[0].departure_time_from
    )
    obj.sectionDivisionEdit.departureTimeTo = formatHHmmss(
      obj.getDatas.selectedItem[0].departure_time_to
    )

    // 着時間
    obj.sectionDivisionEdit.arrivalTimeFrom = formatHHmmss(
      obj.getDatas.selectedItem[0].arrival_time_from
    )
    obj.sectionDivisionEdit.arrivalTimeTo = formatHHmmss(
      obj.getDatas.selectedItem[0].arrival_time_to
    )

    // 出発加算日数
    obj.sectionDivisionEdit.departureAddDays =
      obj.getDatas.selectedItem[0].departure_add_days
    // 到着加算日数
    obj.sectionDivisionEdit.arrivalAddDays =
      obj.getDatas.selectedItem[0].arrival_add_days
  }

  // validate reset
  obj.$refs['tab-group'].$refs['base-tab-group'].reset()
}

export function setBaseArrivalPlaceInfo(obj) {
  if (obj.getDatas) {
    // （基本情報）到着地ＩＤ
    obj.sectionDivisionEdit.arrivalPlaceId = obj.getDatas.baseArrivalPlaceId
    obj.sectionDivisionEdit.arrivalPlaceName = obj.getDatas.baseArrivalPlaceName
  }
}

export function setOnlyDepartureDisable(info) {
  info.departure_place_id.visibility = VISIBILITY.DISABLE
  info.departure_place_name.visibility = VISIBILITY.DISABLE
  info.sub_modal_departure_place.visibility = VISIBILITY.DISABLE
  return info
}

export function setOnlyArrivalDisable(info) {
  info.arrival_place_id.visibility = VISIBILITY.DISABLE
  info.arrival_place_name.visibility = VISIBILITY.DISABLE
  info.sub_modal_arrival_place.visibility = VISIBILITY.DISABLE
  return info
}

export function setDefaultArrivalPlaceInfo(obj) {
  if (
    obj.getDatas.selectedItem != null &&
    obj.getDatas.selectedItem.length > 0
  ) {
    // （選択行）到着地ＩＤ
    obj.sectionDivisionEdit.arrivalPlaceId =
      obj.getDatas.selectedItem[0].arrival_place_id
    obj.sectionDivisionEdit.arrivalPlaceName =
      obj.getDatas.selectedItem[0].arrival_place_name
  }
}

export function setBaseDeparturePlaceInfo(obj) {
  if (obj.getDatas) {
    // （基本情報）出発地ＩＤ
    obj.sectionDivisionEdit.departurePlaceId = obj.getDatas.baseDeparturePlaceId
    obj.sectionDivisionEdit.departurePlaceName =
      obj.getDatas.baseDeparturePlaceName
  }
}

export async function setting(obj) {
  const validated = await obj.$refs['tab-group'].$refs[
    'base-tab-group'
  ].validate()
  if (!validated) return

  var errorFlag = false
  if (
    obj.sectionDivisionEdit.departurePlaceId ==
    obj.sectionDivisionEdit.arrivalPlaceId
  ) {
    errorFlag = true
  }

  // if (obj.getDatas.detailScreenKbn == DETAIL_SCREEN_KBN.NEW) {
  //   if (obj.getDatas.selectedItem.length > 0) {
  //     if (
  //       obj.getDatas.selectedItem[0].departure_place_id !=
  //       obj.sectionDivisionEdit.departurePlaceId
  //     ) {
  //       errorFlag = true
  //     }
  //   }
  // }

  if (errorFlag) {
    // 経由地場所が重複しています。確認してください。
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage']('EB06S001_MG005E')
    )
  } else {
    // let data = obj.sectionDivisionEdit
    // var result = dateCheck(
    //     data.beginTime,
    //     data.endTime,
    //     data.beginWeekday,
    //     data.endWeekday
    // )
    // if (result != null) {
    //     // 期間日付の入力に不整合があります。
    //     openMsgModal(
    //             obj,
    //             MSG_TYPE.W,
    //             obj.$store.getters['init/getMessage']('YZ00MG992E')
    //         )
    //         //obj.$bvModal.hide('edit-modal')
    //     return
    // }
    obj.$bvModal.hide('section-division-edit')
    let newEdit = _.cloneDeep(obj.sectionDivisionEdit)
    obj.$emit('after-close-set', newEdit)
  }
}
